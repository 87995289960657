export const enabledSportBooks = [
    {
        key: 'MGM',
        name: 'BetMGM',
        url: 'https://sports.betmgm.com/en/sports',
    },
    {
        key: 'FANDUEL',
        name: 'FANDUEL',
        url: 'https://www.fanduel.com/',
    },
    {
        key: 'DraftKings',
        name: 'Draft Kings',
        url: 'https://www.draftkings.com/',
    },
    {
        key: 'Bet365',
        name: 'Bet365',
        url: 'https://www.bet365.com/',
    },
    {
        key: 'FanaticsSportsbook',
        name: 'Fanatics Sportsbook',
        url: 'https://sportsbook.fanatics.com/',
    },
];
