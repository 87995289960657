import { SystemProps, x } from '@xstyled/styled-components';
import Hero from 'components/common/Hero';
import HeaderNav from 'components/header/HeaderNav';
import React from 'react';

type HeaderProps = {
    home?: boolean;
    loggedIn?: boolean;
} & SystemProps;
function Header({ home, loggedIn, ...props }: HeaderProps) {
    return (
        <x.header
            className={`heder-slider-area ${
                home && !loggedIn ? 'header-bg' : 'header-bg-subpage'
            }`}
            {...props}
        >
            {/* Header goes here */}
            <HeaderNav loggedIn={loggedIn} />
            <Hero home={home} loggedIn={loggedIn} />
        </x.header>
    );
}

export default Header;
