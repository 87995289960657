import { enabledSports } from 'config/sports';
import React, { ReactNode, useState } from 'react';
import {
    LEAGUE_PAGE_KEY_BET_SIMULATOR,
    LEAGUE_PAGE_KEY_NEWS,
    LEAGUE_PAGE_KEY_ODDS,
    LEAGUE_PAGE_KEY_PICKS,
    LEAGUE_PAGE_KEY_SCORES,
} from 'utils/constants/leagues';

export type ILeaguesConfig = {
    enabled: { key: string; name: string }[];
    pages: {
        key: string;
        label: string;
        enabled?: (leagueKey: string) => boolean;
    }[];
};
type OwnProps = { children: ReactNode };

const LEAGUES_CONFIG_DEFAULT: ILeaguesConfig = {
    enabled: enabledSports,
    pages: [
        { key: LEAGUE_PAGE_KEY_SCORES, label: 'Scores' },
        { key: LEAGUE_PAGE_KEY_ODDS, label: 'Odds' },
        {
            key: LEAGUE_PAGE_KEY_PICKS,
            label: 'Game Picks',
            // enabled: (key) => key.toLowerCase() === 'nba',
        },
        {
            key: LEAGUE_PAGE_KEY_BET_SIMULATOR,
            label: 'Bet Simulator',
            // enabled: (key) => key.toLowerCase() === 'nba',
        },
        { key: LEAGUE_PAGE_KEY_NEWS, label: 'News' },
    ],
};

const LeaguesConfig = React.createContext<ILeaguesConfig>(
    LEAGUES_CONFIG_DEFAULT
);

export function LeaguesConfigProvider({ children }: OwnProps) {
    const [config] = useState<ILeaguesConfig>(LEAGUES_CONFIG_DEFAULT);

    return (
        <LeaguesConfig.Provider value={config}>
            {children}
        </LeaguesConfig.Provider>
    );
}

export default LeaguesConfig;
