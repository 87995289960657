import { SystemProps, x } from '@xstyled/styled-components';
import AppLink from 'components/common/AppLink';
import Typography from 'components/common/Typography';
import { enabledSportBooks } from 'config/sportbooks';
import { validate } from 'email-validator';
import Link from 'next/link';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
    LEAGUE_PAGE_KEY_ODDS,
    LEAGUE_PAGE_KEY_PICKS,
} from 'utils/constants/leagues';
import LeagueLinks from './LeagueLinks';

type FooterProps = {
    home?: boolean;
    loggedIn?: boolean;
} & SystemProps;
function Footer({ loggedIn, ...props }: FooterProps) {
    const [subEmail, setSubEmail] = useState('');
    const [subComplete, setSubComplete] = useState(false);
    const [subError, setSubError] = useState('');

    const currentYear = new Date().getFullYear();

    const onSubmit = async () => {
        setSubError('');

        const valid = validate(subEmail);
        if (!valid) {
            setSubError('Invalid input. Please check the provided email.');
            return;
        }

        const response = await fetch(
            `${process.env.NEXT_PUBLIC_API_ENDPOINT}/sub`,
            {
                method: 'POST',
                body: JSON.stringify({ email: subEmail }),
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                },
            }
        );

        if (response.status === 200) {
            setSubComplete(true);
            setSubEmail('');
        }

        const body = await response.json();
        if (response.status !== 200) {
            setSubError(body.error);
        }
    };
    return (
        <x.footer className='footer-area footer-bg' {...props}>
            <div className='overlay'></div>
            {loggedIn ? (
                <x.div h={{ _: 16, md: 0 }}></x.div>
            ) : (
                <div className='footer-top'>
                    <Container>
                        <div className='subscription-form-area'>
                            <Row className='justify-content-center'>
                                <Col lg={10}>
                                    <div className='subscription-wrapper'>
                                        <Row>
                                            <Col lg={3}>
                                                <span className='subtitle'>
                                                    Keep Up on the Latest
                                                </span>
                                                <h4>Betting Insights</h4>
                                            </Col>
                                            {subComplete !== true ? (
                                                <Col xs={12} lg={9}>
                                                    <div className='subsscribe-form'>
                                                        <div className='form-element-x'>
                                                            <input
                                                                value={subEmail}
                                                                onChange={(e) =>
                                                                    setSubEmail(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                type='email'
                                                                placeholder='Enter your email address'
                                                            />
                                                            <div className='icon'>
                                                                <img src='/img/mail.png' />
                                                            </div>
                                                        </div>
                                                        <input
                                                            onClick={onSubmit}
                                                            type='submit'
                                                            value='Subscribe Now'
                                                        />
                                                    </div>
                                                    {subError ? (
                                                        <x.div
                                                            mt={2}
                                                            display='flex'
                                                            alignItems='center'
                                                            justifyContent='flex-start'
                                                        >
                                                            <Typography
                                                                variant='caption'
                                                                color='error'
                                                            >
                                                                <i className='fas fa-times'></i>
                                                            </Typography>
                                                            <Typography
                                                                ml={2}
                                                                variant='caption'
                                                                color='accentPrimary'
                                                            >
                                                                {subError}
                                                            </Typography>
                                                        </x.div>
                                                    ) : null}
                                                </Col>
                                            ) : (
                                                <Col xs={12} lg={6}>
                                                    <x.div
                                                        pb={{ _: 0, lg: 2 }}
                                                        h='100%'
                                                        display='flex'
                                                        flexDirection={{
                                                            _: 'column',
                                                            lg: 'row',
                                                        }}
                                                        justifyContent={{
                                                            _: 'center',
                                                            lg: 'flex-end',
                                                        }}
                                                        alignItems='center'
                                                    >
                                                        <Typography
                                                            variant='button'
                                                            color='green'
                                                        >
                                                            <i className='fas fa-check'></i>
                                                        </Typography>
                                                        <Typography
                                                            ml={{ _: 0, md: 4 }}
                                                            variant='button'
                                                            color='accentPrimary'
                                                        >
                                                            Thank you for
                                                            subscribing!
                                                        </Typography>
                                                    </x.div>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            )}
            <div className='footer-bottom'>
                <Container>
                    <Row>
                        <Col
                            lg={3}
                            md={6}
                            className='order-lg-0 order-md-4 order-4'
                        >
                            <div className='widget-area about'>
                                <div className='widget-title blank'></div>
                                <div className='widget-body'>
                                    <Link href='/'>
                                        <img
                                            className='footer-logo'
                                            src='/img/footer-logo.png'
                                            alt='sports betting online'
                                        />
                                    </Link>
                                    <p>
                                        Monitor live odds, access matchup data
                                        and reports, follow the smart money, get
                                        expert insights and picks, right here,
                                        with MR. SURE THING.
                                    </p>
                                    <x.div
                                        display='flex'
                                        justifyContent='flex-start'
                                    >
                                        <AppLink href={`/support/tnc`}>
                                            Terms
                                        </AppLink>
                                        <AppLink
                                            ml={6}
                                            href={`/support/privacy`}
                                        >
                                            Privacy
                                        </AppLink>
                                    </x.div>
                                    <div className='social-icons'>
                                        <ul>
                                            <li>
                                                <a href='https://www.facebook.com/mrsurethingbet' target="_blank">
                                                    <i className='fab fa-facebook-f'></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://www.tiktok.com/@mr.surethingbet'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        viewBox='0 0 448 512'
                                                        style={{
                                                            height: '16px',
                                                            padding: '0 2px',
                                                        }}
                                                    >
                                                        <path
                                                            d='M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z'
                                                            fill='white'
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://www.instagram.com/mrsurethingbet' target="_blank">
                                                    <i className='fab fa-instagram'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <LeagueLinks
                                pageKey={LEAGUE_PAGE_KEY_ODDS}
                                title='Sports Betting Odds'
                                linkSuffix='Odds & Betting Lines'
                            />
                        </Col>
                        <Col lg={3} md={6}>
                            <LeagueLinks
                                pageKey={LEAGUE_PAGE_KEY_PICKS}
                                title='Game Picks'
                                linkSuffix='Picks & Analysis'
                            />
                        </Col>
                        <Col lg={3} md={6}>
                            <div className='widget-area about'>
                                <div className='widget-title'>
                                    <h4>Top Sportsbooks</h4>
                                </div>
                                <div className='widget-body'>
                                    <ul className='footer-menu'>
                                        {enabledSportBooks.map((sb) => (
                                            <li key={`sb-${sb.key}`}>
                                                <a href={sb.url}>
                                                    <i className='fas fa-angle-double-right'></i>{' '}
                                                    {sb.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Typography
                                mt={6}
                                variant='caption'
                                color='accentPrimary'
                                lineHeight='relaxed'
                            >
                                <span>DISCLAIMER:</span> If you choose to make
                                use of any information on this website including
                                online sports betting services from any websites
                                that may be featured on this website, we
                                strongly recommend that you carefully check your
                                local laws before doing so. It is your sole
                                responsibility to understand your local laws and
                                observe them strictly. Mr. Sure Thing does not
                                provide any advice or guidance as to the
                                legality of online sports betting or other
                                online gambling activities within your
                                jurisdiction and you are responsible for
                                complying with laws that are applicable to you
                                in your relevant locality. Mr. Sure Thing
                                disclaims all liability associated with your use
                                of this website and use of any information
                                contained on it. As a condition of using this
                                website, you agree to hold the owner of this
                                website harmless from any claims arising from
                                your use of any services on any third party
                                website that may be featured by Mr. Sure Thing.
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='copyright-area'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            Copyright © Mr. Sure Thing {currentYear}. All right
                            reserved.
                        </div>
                    </div>
                </div>
            </div>
        </x.footer>
    );
}

export default Footer;
