import styled, { css, x } from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';
import React, { ReactNode } from 'react';

type TextVariant = 'h1' | 'title' | 'body' | 'button' | 'caption';
type OwnProps = SystemProps & {
    variant?: TextVariant;
    children?: ReactNode;
};

function Typography({ variant = 'body', children, ...props }: OwnProps) {
    return (
        <Wrapper variant={variant} {...props}>
            {children}
        </Wrapper>
    );
}

const Wrapper = styled(x.p)<OwnProps>`
    margin: 0;

    font-size: sm;
    font-weight: normal;
    color: inherit;

    ${(props) => {
        switch (props.variant) {
            case 'h1':
                return css`
                    font-size: 3xl;
                    font-weight: bold;
                    line-height: relaxed;
                `;
            case 'title':
                return css`
                    font-size: 2xl;
                    font-weight: bold;
                    line-height: relaxed;
                `;
            case 'body':
                return css`
                    font-size: default;
                    line-height: normal;
                `;
            case 'button':
                return css`
                    font-size: default;
                    font-weight: bold;
                    line-height: snug;
                `;
            case 'caption':
                return css`
                    font-size: xs;
                    font-weight: thin;
                    line-height: tight;
                `;
            default:
                return '';
        }
    }}
`;

export default Typography;
