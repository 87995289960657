import { x } from '@xstyled/styled-components';
import dynamic from 'next/dynamic';
import React from 'react';
import { REQ_COOKIE_KEY_USER_PERMISSION } from 'utils/constants/requests';
import Typography from './common/Typography';

const CookieConsent = dynamic(() => import('react-cookie-consent'));

type OwnProps = {};
function CookiesNotice({}: OwnProps) {
    return (
        <CookieConsent
            buttonText='I Understand'
            cookieName={REQ_COOKIE_KEY_USER_PERMISSION}
            style={{ background: '#31323a' }}
            buttonStyle={{
                color: '#ffffff',
                fontSize: '12px',
                background: '#f7931e',
            }}
        >
            <x.div display='flex' justifyContent='center'>
                <x.div maxW={{ _: '100%', md: '60%' }}>
                    <Typography variant='caption' lineHeight='relaxed'>
                        We use cookies to personalise content and ads, to
                        provide social media features and to analyse our
                        traffic. We also share information about your use of our
                        site with our social media, advertising and analytics
                        partners who may combine it with other information that
                        you’ve provided to them or that they’ve collected from
                        your use of their services.
                    </Typography>
                </x.div>
            </x.div>
        </CookieConsent>
    );
}

export default CookiesNotice;
