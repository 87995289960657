import { x } from '@xstyled/styled-components';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface OwnProps {
    home?: boolean;
    loggedIn?: boolean;
}

function Hero({ home, loggedIn }: OwnProps) {
    return (
        <>
            {home && !loggedIn ? (
                <>
                    <x.div pointerEvents='none' className='right-image'>
                        <img src='/img/devices.png' alt='app image' />
                    </x.div>
                    <x.div pointerEvents='none' className='bottom-shape'>
                        <img src='/img/banner-shape.png' alt='' />
                    </x.div>
                </>
            ) : null}

            <Container>
                <Row>
                    <Col lg={7} md={12}>
                        {home && !loggedIn ? (
                            <div className='single-slider-area'>
                                <h4 className='subtitle'>
                                    Smart Sports Betting{' '}
                                </h4>
                                <h1 className='title'>
                                    MADE <span>EASY</span>
                                </h1>
                                <p>
                                    Mr. Sure Thing is a new, dynamic sports
                                    entertainment web application that provides
                                    users with access to the latest sports news,
                                    sports betting analysis, and betting odds,
                                    as well as a unique, proprietary AI-driven
                                    algorithm that is able to accurately predict
                                    the winners, winning percentages and point
                                    spreads for matchups across the major US
                                    sports leagues.
                                </p>
                                <div className='header-btn-group'>
                                    <a
                                        href={`/user/signup`}
                                        className='boxed-btn'
                                    >
                                        Sign Up Today
                                    </a>
                                </div>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Hero;
