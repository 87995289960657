import { ILeaguesConfig } from 'context/LeaguesConfig';
import useLeaguesConfig from 'hooks/useLeaguesConfig';
import React, { ReactNode } from 'react';

type OwnProps = {
    title?: string;
    pageKey: string;
    linkSuffix: string;
};
function LeagueLinks({ title, pageKey, linkSuffix }: OwnProps) {
    const {
        enabled: enabledLeagues,
        pages: pagesPerLeague,
    } = useLeaguesConfig<ILeaguesConfig>();

    return (
        <div className='widget-area about'>
            {title ? (
                <div className='widget-title'>
                    <h4>{title}</h4>
                </div>
            ) : null}
            <div className='widget-body'>
                <ul className='footer-menu'>
                    {/* Odds quick links */}
                    {pagesPerLeague.reduce<ReactNode[]>(
                        (links, page) =>
                            page.key === pageKey
                                ? [
                                      ...links,
                                      ...enabledLeagues.map((l) =>
                                          page.enabled === undefined ||
                                          page.enabled(l.key) ? (
                                              <li
                                                  key={`f-${
                                                      page.key
                                                  }-${l.key.toLowerCase()}`}
                                              >
                                                  <a
                                                      href={`/sport/${l.key.toLowerCase()}/${
                                                          page.key
                                                      }`}
                                                  >
                                                      <i className='fas fa-angle-double-right'></i>{' '}
                                                      {`${l.key.toUpperCase()} ${linkSuffix}`}
                                                  </a>
                                              </li>
                                          ) : null
                                      ),
                                  ]
                                : links,
                        []
                    )}
                </ul>
            </div>
        </div>
    );
}

export default LeagueLinks;
