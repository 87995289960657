import styled, { x } from '@xstyled/styled-components';
import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

type OwnProps = {
    id?: string;
    fluid?: boolean;
    center?: boolean;
    fill?: boolean;
    className?: string;
    title?: string;
    children?: ReactNode;
};

function PageSection({
    id,
    fluid,
    children,
    title,
    center,
    fill,
    className = '',
}: OwnProps) {
    return (
        <SectionWrapper
            id={id}
            className={className}
            pt={{ _: 10, md: 20 }}
            pb={6}
        >
            <Container fluid={fluid}>
                {title ? (
                    <Row className='title-row justify-content-center'>
                        <Col lg={6}>
                            <x.div className='section-title bg-white'>
                                <h2
                                    className='title'
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                            </x.div>
                        </Col>
                    </Row>
                ) : null}
                <Row
                    className={`content-row ${center ? '__center' : ''} ${
                        fill ? '__fill' : ''
                    }`}
                >
                    {children}
                </Row>
            </Container>
        </SectionWrapper>
    );
}

const SectionWrapper = styled(x.section)`
    /* Section heading Area Start */
    .section-title {
        color: secondary;
        margin-bottom: 30px;
        text-align: center;

        @media (max-width: md) {
            margin-bottom: 20px;
        }
    }

    .section-title.extra-padding {
    }

    .section-title .title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 12px;
        color: #fff;
        text-transform: uppercase;
    }

    .section-title.bg-white .title {
        color: #333;
    }

    .section-title .text {
        font-size: 16px;
        line-height: 26px;
        color: #fff;
        margin-bottom: 0px;
    }

    .section-title.bg-white .text {
        color: #31323a;
    }

    .section-title .title span {
        color: #f7931e;
    }

    .section-title .sub-title {
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    .content-row {
        &.__center {
            justify-content: center;
        }

        &.__fill {
            width: 100%;
        }
    }
`;

export default PageSection;
