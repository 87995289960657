import { SystemProps, useColor, x } from '@xstyled/styled-components';
import React, { ReactNode } from 'react';
import Typography from './Typography';

type OwnProps = {
    children?: ReactNode;
    newTab?: boolean;
    label?: string;
    replace?: boolean;
    href: string;
};
function AppLink({
    children,
    label,
    href,
    newTab,
    replace,
    ...props
}: OwnProps & SystemProps) {
    const primary = useColor('primary');
    const secondary = useColor('secondary');
    return (
        <x.a
            href={href}
            color={props.color || secondary}
            hoverColor={props.color || primary}
            {...props}
            {...(newTab ? { target: '_blank', rel: 'noreferrer' } : {})}
            textDecoration={`underline dashed ${primary}`}
            hoverTextDecoration={`underline dashed ${secondary}`}
        >
            <Typography variant='body' fontSize='sm'>
                {label || children}
            </Typography>
        </x.a>
    );
}

export default AppLink;
