import {
    EVENT_FILTER_KEY_SEASON_YEAR,
    LEAGUE_FILTER_TYPE_DAY,
    LEAGUE_FILTER_TYPE_WEEK,
} from 'utils/constants/leagues';

// Find the sportsDBLeague using: https://www.thesportsdb.com/api/v1/json/1/all_leagues.php
export type LeagueDef = {
    defaultID: number;
    key: string;
    name: string;
    sportsDBLeague: string;
    filterType:
        | typeof LEAGUE_FILTER_TYPE_DAY
        | typeof LEAGUE_FILTER_TYPE_WEEK
        | typeof EVENT_FILTER_KEY_SEASON_YEAR
        | typeof LEAGUE_FILTER_TYPE_WEEK;
};

export const enabledSports: LeagueDef[] = [
    {
        defaultID: 1,
        key: 'NFL',
        name: `NFL`,
        sportsDBLeague: 'NFL',
        filterType: LEAGUE_FILTER_TYPE_DAY,
    },
    {
        defaultID: 2,
        key: 'NBA',
        name: `NBA`,
        sportsDBLeague: 'NBA',
        filterType: LEAGUE_FILTER_TYPE_DAY,
    },
    {
        defaultID: 3,
        key: 'MLB',
        name: `MLB`,
        sportsDBLeague: 'MLB',
        filterType: LEAGUE_FILTER_TYPE_DAY,
    },
    {
        defaultID: 4,
        key: 'NHL',
        name: `NHL`,
        sportsDBLeague: 'NHL',
        filterType: LEAGUE_FILTER_TYPE_DAY,
    },
    // {
    //     defaultID: 5,
    //     key: 'NCAAB',
    //     name: `NCAA Men's Basketball`,
    //     sportsDBLeague: 'NCAA Division I Basketball Mens',
    //     filterType: LEAGUE_FILTER_TYPE_DAY,
    // },
    // {
    //     defaultID: 6,
    //     key: 'NCAAF',
    //     name: `NCAA Football`,
    //     sportsDBLeague: 'NCAA Division 1',
    //     filterType: LEAGUE_FILTER_TYPE_DAY,
    // },
];
