export const LEAGUE_PAGE_KEY_SCORES = 'scores';
export const LEAGUE_PAGE_KEY_ODDS = 'odds';
export const LEAGUE_PAGE_KEY_PICKS = 'picks';
export const LEAGUE_PAGE_KEY_BET_SIMULATOR = 'bet-simulator';
export const LEAGUE_PAGE_KEY_NEWS = 'news';

export const EVENT_STATUS_SCHEDULED = 'STATUS_SCHEDULED';
export const EVENT_STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS';
export const EVENT_STATUS_FINAL = 'closed';
export const EVENT_STATUS_SUSPENDED = 'STATUS_SUSPENDED';
export const EVENT_STATUS_POSTPONED = 'STATUS_POSTPONED';
export const EVENT_STATUS_CANCELED = 'STATUS_CANCELED';
export const EVENT_NOT_STARTED = 'not_started';

export const EVENT_STATUS_TEXT: { [key: string]: string } = {
    [EVENT_STATUS_FINAL]: 'Final',
    [EVENT_STATUS_CANCELED]: 'Cancelled',
    [EVENT_STATUS_SCHEDULED]: 'Matchup',
    [EVENT_STATUS_SUSPENDED]: 'Suspended',
    [EVENT_STATUS_POSTPONED]: 'Postponed',
    [EVENT_STATUS_IN_PROGRESS]: 'Live',
};

export const DEFERRED_EVENT_STATUS_LIST = [
    EVENT_STATUS_SUSPENDED,
    EVENT_STATUS_CANCELED,
    EVENT_STATUS_POSTPONED,
] as const;

export type DeferredEventStatus = (typeof DEFERRED_EVENT_STATUS_LIST)[number];

export const LEAGUE_FILTER_TYPE_DAY = 'filter_day';
export const LEAGUE_FILTER_TYPE_WEEK = 'filter_week';

export const EVENT_FILTER_KEY_DATE = 'd';
export const EVENT_FILTER_KEY_DATE_OFFSET = 'o';
export const EVENT_FILTER_KEY_SEASON_WEEK = 'w';
export const EVENT_FILTER_KEY_SEASON_YEAR = 'y';
export const EVENT_FILTER_KEY_A_WEEK = 'r';
export const EVENT_FILTER_KEY_SPORT = 's';
export const EVENT_FILTER_KEY_TEAM = 't';
export const EVENT_FILTER_KEY_INCLUDES = 'inc';
export const EVENT_FILTER_KEY_ID = 'id';

export const TIMEZONE_DEFAULT_ODDS = 'America/New_York';

export const ODDS_TYPE_MONEYLINE = 'moneyLine';
export const ODDS_TYPE_TOTALS = 'total';
export const ODDS_TYPE_SPREAD = 'spread';
