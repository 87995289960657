export const REQUEST_QUERY_KEY_NOTIFY = 'notify';
export const REQUEST_QUERY_KEY_LEAGUE = 'league';
export const REQUEST_QEURY_KEY_REDO = 'redo';
export const REQUEST_QEURY_KEY_ACTION = 'action';

export const REQUEST_HEADER_MST_TZ = 'x-mst-tz';
export const REQUEST_HEADER_API_KEY_MST_SYNC = 'x-mst-synckey';

export const REQUEST_EVENT_INCLUDE_ODDS = 'odds';
export const REQUEST_EVENT_INCLUDE_PICKS = 'picks';

export const REQ_SESSION_KEY_USERID = 'userId';

export const REQ_BODY_KEY_SUBSCRIPTION_TYPE = 'subType';

export const REQ_COOKIE_KEY_AUTH = 'mst/user';
export const REQ_COOKIE_KEY_USER_PERMISSION = 'cookies-accepted';

export const QUERY_KEY_EVENT = 'event';
export const QUERY_KEY_FROM = 'from';
export const QUERY_KEY_TO = 'to';
export const QUERY_KEY_INCLUDE = 'include';
