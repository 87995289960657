import styled, { css } from '@xstyled/styled-components';
import {
    Button,
    ButtonProps,
    ToggleButton,
    ToggleButtonProps,
} from 'react-bootstrap';

type AppButtonProps = {
    toggleColor?: string;
    fluid?: boolean;
};
type WrapperProps = AppButtonProps & (ButtonProps | ToggleButtonProps);

const Wrapped = ({
    fluid,
    toggleColor,
    Component,
    ...props
}: WrapperProps & { Component: any }) => <Component {...props} />;

export const Wrapper = styled(Wrapped)`
    /* > button { */
    ${(props) =>
        props.color
            ? css`
                  box-shadow: none !important;
                  ${(props.variant || '').startsWith('outline-') !== true
                      ? `background-color: ${props.color}`
                      : ''};
                  border-color: ${props.color};
                  &:hover,
                  &:focus,
                  &:active {
                      background-color: ${props.color} !important;
                      border-color: ${props.color} !important;
                  }

                  &.active {
                      background-color: ${props.toggleColor} !important;
                      border-color: ${props.toggleColor} !important;
                  }
              `
            : ''}

    ${(props: AppButtonProps & (ButtonProps | ToggleButtonProps)) =>
        props.fluid
            ? css`
                  width: 100%;
                  height: 100%;
              `
            : ''} /* } */
`;

export default function AppButton({
    toggleColor,
    fluid,
    ...props
}: AppButtonProps & ButtonProps) {
    return (
        <Wrapper
            Component={Button}
            toggleColor={toggleColor}
            fluid={fluid}
            {...props}
        />
    );
}

export function AppToggleButton({
    toggleColor,
    fluid,
    ...props
}: AppButtonProps & ToggleButtonProps) {
    return (
        <Wrapper
            Component={ToggleButton}
            toggleColor={toggleColor}
            fluid={fluid}
            {...props}
        />
    );
}
