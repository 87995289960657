import AppButton from 'components/common/AppButton';
import Typography from 'components/common/Typography';
import { ILeaguesConfig } from 'context/LeaguesConfig';
import useLeaguesConfig from 'hooks/useLeaguesConfig';
import useUser from 'hooks/useUser';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { fetchJson } from 'utils/api';

type HeaderNavProps = {
    loggedIn?: boolean;
};
function HeaderNav({ loggedIn }: HeaderNavProps) {
    const { enabled: enabledLeagues, pages: pagesPerLeague } =
        useLeaguesConfig<ILeaguesConfig>();

    const active = useMemo(() => {
        let activeImage: {
            [key: string]: {
                img: string;
                activeImg: string;
                status: boolean;
            };
        } = {};
        enabledLeagues.map((el) => {
            activeImage[el.key] = {
                img: `/img/logos/sports/${el.key.toLowerCase()}.svg`,
                activeImg: `/img/logos/sports/${el.key.toLowerCase()}-active.svg`,
                status: false,
            };
        });
        return activeImage;
    }, [enabledLeagues]);

    const [currentActive, setCurrentActive] = useState(-1);

    const router = useRouter();

    const { mutateUser } = useUser();

    return (
        <div className='navigation'>
            <Container>
                <Row>
                    <Col lg={12} className='p-0'>
                        <Navbar bg='light' expand='lg'>
                            <Navbar.Brand href='/'>
                                <img
                                    src='/img/mst-logo.svg'
                                    height='58'
                                    alt='Mr. Sure thin'
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls='mainmenu'>
                                <span className='navbar-toggler-icon'></span>
                            </Navbar.Toggle>
                            <Navbar.Collapse id='mainmenu'>
                                <Nav className='ml-auto mr-auto'>
                                    {enabledLeagues.map((l, index) => (
                                        <NavDropdown
                                            title={
                                                <span
                                                    style={{
                                                        color:
                                                            currentActive ==
                                                            index
                                                                ? '#f7931e'
                                                                : '#000',
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            currentActive ==
                                                            index
                                                                ? active[l.key]
                                                                      .activeImg
                                                                : active[l.key]
                                                                      .img
                                                        }
                                                        height='20'
                                                        className='pr-1'
                                                        alt={l.key}
                                                    />
                                                    {l.key}
                                                </span>
                                            }
                                            onMouseOver={() =>
                                                setCurrentActive(index)
                                            }
                                            onMouseOut={() =>
                                                setCurrentActive(-1)
                                            }
                                            key={`league-dd-${l.key}`}
                                            id={`header-dd-${l.key}`}
                                        >
                                            {pagesPerLeague.map((p) =>
                                                p.enabled === undefined ||
                                                p.enabled(l.key) ? (
                                                    <NavDropdown.Item
                                                        key={`ddi-${p.key}`}
                                                        href={`/sport/${l.key.toLowerCase()}/${p.key.toLowerCase()}`}
                                                    >
                                                        {p.label}
                                                    </NavDropdown.Item>
                                                ) : null
                                            )}
                                        </NavDropdown>
                                    ))}

                                    <NavDropdown
                                        title='Resources'
                                        id='header-res'
                                    >
                                        <NavDropdown.Item href='/blog/how-to-bet'>
                                            How to Bet
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href='/blog/betting-101'>
                                            Betting 101
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>

                                <Nav className='ml-auto'>
                                    {loggedIn ? (
                                        <AppButton
                                            onClick={() =>
                                                router.push(`/user/profile`)
                                            }
                                            variant='Link'
                                        >
                                            <Typography
                                                fontSize='sm'
                                                variant='button'
                                            >
                                                PROFILE
                                            </Typography>
                                        </AppButton>
                                    ) : (
                                        <AppButton
                                            onClick={() =>
                                                router.push(`/user/login`)
                                            }
                                            variant='Link'
                                        >
                                            <Typography
                                                fontSize='sm'
                                                variant='button'
                                            >
                                                LOGIN
                                            </Typography>
                                        </AppButton>
                                    )}
                                    {loggedIn ? (
                                        <AppButton
                                            onClick={async () => {
                                                await mutateUser(
                                                    await fetchJson(
                                                        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/logout`,
                                                        { method: 'POST' }
                                                    )
                                                );
                                            }}
                                            variant='primary'
                                            color='primary'
                                        >
                                            <Typography
                                                fontSize='sm'
                                                variant='button'
                                            >
                                                LOGOUT
                                            </Typography>
                                        </AppButton>
                                    ) : (
                                        <AppButton
                                            onClick={() =>
                                                router.push(`/user/signup`)
                                            }
                                            variant='primary'
                                            color='primary'
                                        >
                                            <Typography
                                                fontSize='sm'
                                                variant='button'
                                            >
                                                REGISTER
                                            </Typography>
                                        </AppButton>
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderNav;
