import { AppUser } from 'interfaces/user';
import Router from 'next/router';
import { useEffect } from 'react';
import useSWR from 'swr';

type OwnProps = {
    redirectTo?: string;
    redirectIfFound?: boolean;
};
export default function useUser({
    redirectTo,
    redirectIfFound,
}: OwnProps = {}) {
    const { data: user, error, mutate: mutateUser } = useSWR<AppUser>(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/profile`,
        {
            refreshInterval: 0,
            shouldRetryOnError: false,
        }
    );

    const loading = (!user || !user.id) && !error;

    useEffect(() => {
        // if no redirect needed, just return (example: already on /dashboard)
        // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
        if (!redirectTo || !user?.id) return;

        if (
            // If redirectTo is set, redirect if the user was not found.
            (redirectTo && !redirectIfFound && !user?.id) ||
            // If redirectIfFound is also set, redirect if the user was found
            (redirectIfFound && user?.id)
        ) {
            Router.push(redirectTo);
        }
    }, [user, redirectIfFound, redirectTo]);

    return { user, loading, error, mutateUser };
}
