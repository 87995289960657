import LeaguesConfig, { ILeaguesConfig } from 'context/LeaguesConfig';
import { useContext, useMemo } from 'react';

function useLeaguesConfig<T>(selector?: (ctx: ILeaguesConfig) => T) {
    const ctx = useContext(LeaguesConfig);

    return useMemo<T | ILeaguesConfig>(
        () => (ctx && selector ? selector(ctx) : ctx),
        [ctx]
    );
}

export default useLeaguesConfig;
