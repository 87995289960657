import { x } from '@xstyled/styled-components';
import CookiesNotice from 'components/CookiesNotice';
import Footer from 'components/footer';
import Header from 'components/header';
import seo from 'config/seo';
import useUser from 'hooks/useUser';
import Head from 'next/head';
import React, { ReactNode } from 'react';

type Props = {
    children?: ReactNode;
    title?: string;
    home?: boolean;
    cookies?: boolean;
};

const Layout = ({ children, title, home, cookies = true }: Props) => {
    const { user } = useUser();
    const loggedIn = Boolean(user?.id);

    return (
        <>
            <Head>
                {title ? <title>{title}</title> : null}
                <meta charSet='utf-8' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <meta name='theme-color' content='#000000' />
                <link
                    rel='apple-touch-icon'
                    href={`${seo.canonical}/logo192.png`}
                />
            </Head>

            <Header home={home} loggedIn={loggedIn} pt={2} zIndex={99} />

            <x.main>
                {children}
                {cookies ? <CookiesNotice /> : null}
            </x.main>

            <Footer home={home} loggedIn={loggedIn} mt={{ _: 32, lg: 20 }} />
        </>
    );
};

export default Layout;
